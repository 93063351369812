import React, { useState } from 'react';
import logo from './uts-brand-circle.svg';
import './App.css';

function App() {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    const formData = { "form-name": "email-capture", email: email };
  
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode(formData)
    })
    .then(() => {
      setIsSubmitted(true); // Handle success
      setEmail(''); // Clear the input field
    })
    .catch(error => setErrorMessage('Submission failed: ' + error.message));
  };
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Spring 2025</h1>
        <p>Join the list</p>
        {isSubmitted ? (
          <div className="confirmation-message">Thank you! We will be in touch.</div>
        ) : (
          <form
            className="email-form"
            name="email-capture"
            method="POST"
            data-netlify="true"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="email-capture" />
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit" className="button">Sign Up</button>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
          </form>
        )}
      </header>
    </div>
  );
}

export default App;